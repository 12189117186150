<template>
  <div class="content-div">
    <h1>Digital Certificates and Authentication</h1>
    <v-expansion-panels multiple > 
      <OlsExpansion v-for="(item,i) in items" :key="i" :order="i+1" :title="item.title" :type="0"><div v-html="item.content"></div></OlsExpansion>
    </v-expansion-panels>
  </div>
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import OlsExpansion from '../../components/misc/ols-expansion'

export default {
  name: 'FAQDca',
  components: {
    BoldLine,
    OlsExpansion,
  },
  data: function () {
    return {
      items:[
          {
            title:'What is a digital certificate?',
            content:'<p class="expansion">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>',
          },{
            title:'Which types of digital certificates do online licence services support?',
            content:'<p class="expansion">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>',
          },{
            title:'What is the Hongkong Post e-Cert (Personal) for the Smart ID Card? Where can I get more information about it?',
            content:'<p class="expansion">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>',
          },{
            title:'I am using Windows Vista and Internet Explorer to access an online service that requires user authentication. During user authentication, the screen displays security warnings - "Windows needs your permission to continue" and "A website wants to open web content using this program on your computer". What should I do?',
            content:'<p class="expansion">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>',
          },{
            title:'When I tried to use my Digi-Sign Personal ID-Cert to sign an online licence service, I got an error message saying that the certificate could not be found. What should I do?',
            content:'<p class="expansion">The problem probably occurs after the password of the certificate has been changed. If you encounter this problem, please import the certificate into Internet Explorer or Firefox and export a backup copy. You should be able to use the backup copy to login to the online service. Please refer to the ( <a href="http://www.dg-sign.com/eng/frame_service.htm" target="_blank">http://www.dg-sign.com/eng/frame_service.htm</a> ) in Digi-Sign website on how to import and export the ID-Cert.</p>',
          },
        ]
        
    }
  },
}
</script>

<style scoped lang="scss">
p.expansion{
  margin-left:60px;
}

a{
      text-decoration: none !important;
    p{
      color:$ols-primary !important;
    }
}
//md and down
@media screen and (max-width: 1263px) {
  
}

</style>